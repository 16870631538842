import { render, staticRenderFns } from "./BorrowingPage_FundingDirect.vue?vue&type=template&id=208ebfe2&scoped=true&"
import script from "./BorrowingPage_FundingDirect.vue?vue&type=script&lang=js&"
export * from "./BorrowingPage_FundingDirect.vue?vue&type=script&lang=js&"
import style0 from "./BorrowingPage_FundingDirect.vue?vue&type=style&index=0&id=208ebfe2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "208ebfe2",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIcon});
