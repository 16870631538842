<template>
	<div :class="'borrowing-page-community' + (isActive ? ' active' : '')">
		<div class="summary-container">
			<span class="summary-title">
				Community
			</span>

			<div class="nb-vspacer" />

			<span class="summary-desc">
				Coming soon - Pre-register now and be first in line for the beta launch.
				<div class="nb-vspacer mini" />
				To pre-register, please send your email to <a class="flat-button" href="mailto: info@neobanx.com">info@neobanx.com</a>, 
				or use the <a class="flat-button" @click="$router.push('/contact-us')">contact form</a>.
			</span>
		</div>
	</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
	name: 'LandingPage_Community',

	props: {
		isActive: false
	},

	setup() {
		// TODO	
	}
})
</script>

<style lang="scss" scoped>
.borrowing-page-community {
	display: flex;
	flex-direction: column;
	position: absolute;
	opacity: 0;
	transition: opacity 1s;
	height: 0;
	overflow: hidden;

	.summary-container {
		display: flex;
		flex-direction: column;

		.summary-title {
			display: flex;
			font-size: 20px;
			line-height: 24px;
			font-family: MartelRegular;
			font-weight: bold;
			color: #5761A7;
		}

		.summary-desc {
			font-size: 17px;
			line-height: 22px;
			color: rgba(#000, 0.7);

			.flat-button {
				cursor: pointer;
				font-weight: bolder;
				color: #5761A7;
			}
		}
	}
}

.borrowing-page-community.active {
	opacity: 1;
	height: unset;
	overflow: unset;
}
</style>
