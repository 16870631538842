<template>
	<div :class="'borrowing-page-start-now' + (isActive ? ' active' : '')">
		<div class="summary-container">
			<span class="summary-title">
				<span>Start Borrowing Money Now</span>
				
				<div class="nb-vspacer mini" />

				<span>Thank you for your interest in Neobanx!</span>
			</span>

			<div class="nb-vspacer" />

			<span class="summary-desc">
				We are now in our pre-public release phase. For competitive reasons and to protect our investment in 
				this project, access to the Neobanx site currently requires an invitation. If you are a Canadian 
				resident over the age of eighteen, please contact us if you wish to participate or learn more, and we 
				will add you to our invitation list.

				<div class="nb-vspacer" />

				<a class="button" @click="$router.push('/contact-us')">
					Contact Us
				</a>

				<div class="nb-vspacer" />

				<span>
					If you are already a member of Neobanx, please 
					<a class="flat-button" @click="$router.push('/login')">sign in</a>
					to start.
				</span>
			</span>
		</div>
	</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
	name: 'BorrowingPage_StartNow',

	props: {
		isActive: false
	},

	setup() {
		// TODO
	}
})
</script>

<style lang="scss" scoped>
.borrowing-page-start-now {
	display: flex;
	flex-direction: column;
	position: absolute;
	opacity: 0;
	transition: opacity 1s;
	height: 0;
	overflow: hidden;

	.summary-container {
		display: flex;
		flex-direction: column;

		.summary-title {
			display: flex;
			flex-direction: column;
			font-size: 20px;
			line-height: 24px;
			font-family: MartelRegular;
			font-weight: bold;
			color: #5761A7;

			> :first-child {
				color: #EA7914;
				font-size: 18px;
			}
		}

		.summary-desc {
			font-size: 17px;
			line-height: 22px;
			color: rgba(#000, 0.7);

			.flat-button {
				font-weight: bolder;
				color: #5761A7;
				cursor: pointer;
			}
		}
	}

	.button {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 36px;
		padding: 0 16px;
		width: fit-content;
		border-radius: 4px;
		font-weight: bolder;
		font-size: 14px;
		color: rgba(#fff, 0.8);
		text-transform: uppercase;
		cursor: pointer;
		background: linear-gradient(135deg, #EA7916 0, #D16C13 100%);
	}

	.button:hover {
		filter: brightness(110%);
	}
}

.borrowing-page-start-now.active {
	opacity: 1;
	height: unset;
	overflow: unset;
}
</style>
