<template>
	<div :class="'borrowing-page-funding-direct' + (isActive ? ' active' : '')">
		<div class="header">
			<div class="title">
				Borrower Loan Proposals
			</div>

			<div class="nb-vspacer" />

			<div class="steps">
				<div class="step">
					<span class="step-title">Create</span>
					
					<div class="nb-vspacer mini" />

					<span class="step-desc">Create a loan proposal with your borrowing terms.</span>
				</div>

				<div class="divider">
					<q-icon class="icon" name="arrow_forward" />
				</div>

				<div class="step">
					<span class="step-title">Share</span>
					
					<div class="nb-vspacer mini" />

					<span class="step-desc">Share your proposal with friends and seek offers.</span>
				</div>

				<div class="divider">
					<q-icon class="icon" name="arrow_forward" />
				</div>

				<div class="step">
					<span class="step-title">Loan</span>
					
					<div class="nb-vspacer mini" />

					<span class="step-desc">
						Now you have a loan!
						<br /><br /><br />
					</span>
				</div>
			</div>

			<div class="nb-vspacer" />

			<div class="desc">
				<span>
					Perhaps you've already made a verbal agreement to do a loan with someone and you just need a way to 
					formalize it. Or maybe you're looking to approach someone that you know with a proposal for a loan.
				</span>

				<div class="nb-vspacer mini" />

				<span>
					<b>Whatever the case may be, we can help!</b>
				</span>
			</div>
		</div>

		<div class="nb-vspacer xxlarge" />

		<div class="summary-container">
			<span class="summary-title">
				What We Can Do
			</span>

			<div class="nb-vspacer" />

			<span class="summary-desc">
				If you want to take an informal arrangement between friends or family members and give it the clarity 
				and security that would provide reassurance to both parties, consider the features we offer:

				<ul>
					<li>
						Full legal Promissory Note
					</li>

					<li>
						Payments automatically collected by Pre-Authorized Debit
					</li>

					<li>
						Peace of mind with our Loan Protection programs (if the borrower is in a regular job; various conditions apply)
					</li>
				</ul>

				We also offer a host of flexible and constructive tools that allow people who borrow and lend to deal with problems 
				that might arise, such as skipped payments, or the need to reschedule payments, or even to allow making extra payments 
				to shorten the length of the loan. Even if your friend or family should run into problems such as losing their job or 
				becoming sick or disabled, our Loan Protection program can help guard against these risks.

				<div class="nb-vspacer mini" />

				If you are considering approaching a family member or friend to ask for a loan, we can help by providing you with the 
				tools to make the right approach including:

				<ul>
					<li>
						A detailed loan proposal that you can send by email or any method you chose, including details of how you will 
						pay the loan back and what the lender will make in interest.
					</li>

					<li>
						An offer to the potential lender that you will take out loan protection to help protect him or her against the 
						risks of unemployment, sickness or disability.
					</li>

					<li>
						A mechanism that assists you and your potential lender to negotiate using counter-proposals until you come to a 
						mutual agreement.
					</li>
				</ul>
			</span>
		</div>
		
		<div class="hr" />

		<div class="summary-container">
			<span class="summary-title">
				How It Works
			</span>

			<div class="nb-vspacer" />

			<span class="summary-desc">
				Here's how it works:

				<ul>
					<li>
						Create a loan proposal, setting out how much you need, for how long, and what interest rate you are willing to pay.
					</li>

					<li>
						Add your personal message and Neobanx will also provide the potential lender with a description of how the whole process works.
					</li>

					<li>
						Tell us who you want to send the proposal to. It doesn't matter if they're already signed up with Neobanx or not — registering is free.
					</li>
				</ul>

				Once the proposal is sent, the potential lender can either accept your proposal and upload money to fund the loan, or they can respond with a 
				counter-proposal which you can either accept or in turn make your own counter response. There is no limit to how many times you can send 
				counter-proposals back and forth.

				<div class="nb-vspacer mini" />

				Once you have both agreed to the terms and the lender funds the loan, the process is complete.
			</span>
		</div>

		<div class="nb-vspacer xlarge" />

		<a class="button" @click="$emit('goto', 'StartNow')">
			Start Borrowing Money Now
		</a>

		<div class="nb-vspacer xxxlarge" />
	</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
	name: 'BorrowingPageFundingDirect',

	props: {
		isActive: false
	},

	setup() {
		// TODO
	}
})
</script>

<style lang="scss" scoped>
.borrowing-page-funding-direct {
	display: flex;
	flex-direction: column;
	position: absolute;
	opacity: 0;
	transition: opacity 1s;
	height: 0;
	overflow: hidden;

	.header {
		display: flex;
		flex-direction: column;
		padding: 24px 32px;
		background-color: #5761A7;
		border-radius: 8px;

		.title {
			font-size: 20px;
			font-family: Quicksand;
			font-weight: bold;
			color: #FF9800;
		}

		.steps {
			display: flex;
			justify-content: center;
			width: 100%;
			text-align: center;

			.step {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 180px;
				height: 180px;
				background-color: #fff;
				padding: 16px;
				border-radius: 50%;

				.step-title {
					font-weight: bolder;
					text-transform: uppercase;
					font-size: 16px;
					color: #5761A7;
				}

				.step-desc {
					line-height: 18px;
					color: rgba(#000, 0.7);
				}
			}

			.divider {
				display: flex;
				align-items: center;
				padding: 16px;

				.icon {
					font-size: 40px;
					//color: rgba(#FF9800, 0.5);
					color: #DDB244;
				}
			}
		}

		.desc {
			font-size: 15px;
			color: rgba(#fff, 0.6);
		}
	}

	.summary-container {
		display: flex;
		flex-direction: column;

		.summary-title {
			display: flex;
			font-size: 20px;
			line-height: 24px;
			font-family: MartelRegular;
			font-weight: bold;
			color: #5761A7;
		}

		.summary-desc {
			font-size: 17px;
			line-height: 22px;
			color: rgba(#000, 0.7);

			ul {
				list-style: none;

				li {
					padding-left: 12px;
					padding-bottom: 4px;
				}

				li::marker {
					content: "\25FC";
					color: #EA7914;
				}
			}
		}
	}

	.button {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 36px;
		padding: 0 16px;
		width: fit-content;
		border-radius: 4px;
		font-weight: bolder;
		font-size: 14px;
		color: rgba(#fff, 0.8);
		text-transform: uppercase;
		cursor: pointer;
		background: linear-gradient(135deg, #EA7916 0, #D16C13 100%);
	}

	.button:hover {
		filter: brightness(110%);
	}

	.hr {
		display: flex;
		width: 30%;
		margin-top: 28px;
		border-top: 1px dotted #FF9800;
		padding-bottom: 36px;
	}
}

.borrowing-page-funding-direct.active {
	opacity: 1;
	height: unset;
	overflow: unset;
}
</style>
