








































































import { defineComponent, reactive, watch } from '@vue/composition-api'
import BorrowingPage_Overview from '@/views/BorrowingPageSections/BorrowingPage_Overview.vue'
import BorrowingPage_MeetingPlace from '@/views/BorrowingPageSections/BorrowingPage_MeetingPlace.vue'
import BorrowingPage_Community from '@/views/BorrowingPageSections/BorrowingPage_Community.vue'
import BorrowingPage_FundingDirect from '@/views/BorrowingPageSections/BorrowingPage_FundingDirect.vue'
import BorrowingPage_StartNow from '@/views/BorrowingPageSections/BorrowingPage_StartNow.vue'

export default defineComponent({
	name: 'BorrowingPage',

	components: {
		BorrowingPage_Overview,
		BorrowingPage_MeetingPlace,
		BorrowingPage_Community,
		BorrowingPage_FundingDirect,
		BorrowingPage_StartNow
	},

	props: {},

	setup(props, { root }) {
		const state = reactive({
			activeSection: 'Overview'
		});

		watch(
			() => state.activeSection,
			() => {
				document.getElementById('borrowing-content')!.scrollTop = 0
			}
		)

		if (root.$route.query.section == 'start-now') {
			state.activeSection = 'StartNow'
			root.$router.replace('/borrowing')
		}

		return { state }
	}
})
