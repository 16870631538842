<template>
	<div :class="'borrowing-page-overview' + (isActive ? ' active' : '')">
		<div class="summary-container">
			<span class="summary-title">
				The Meeting Place
			</span>

			<div class="nb-vspacer" />

			<span class="summary-desc">
				Connect with people across the country. View the loans on offer or create your own listing seeking a loan.
			</span>

			<div class="nb-vspacer" />

			<div class="action">
				<a class="flat-button" @click="$emit('goto', 'MeetingPlace')">
					Learn more

					<q-icon class="icon" name="arrow_forward" />
				</a>

				<a class="button" @click="$router.push('/login')">
					Borrow in the Meeting Place

					<q-icon class="icon" name="double_arrow" />
				</a>
			</div>
		</div>
		
		<div class="hr" />

		<div class="summary-container">
			<span class="summary-title">
				Borrowing within your Community
			</span>

			<div class="nb-vspacer" />

			<span class="summary-desc">
				Seek a loan from someone you share a connection or bond with in our online Lending Communities.
				<div class="nb-vspacer mini" />
				Don't see a Community that represents the bonds you have with others? Start your own...
			</span>

			<div class="nb-vspacer" />

			<div class="action">
				<a class="flat-button" @click="$emit('goto', 'Community')">
					Learn more

					<q-icon class="icon" name="arrow_forward" />
				</a>

				<a class="button" @click="$router.push('/community')">
					Borrow in a Community

					<q-icon class="icon" name="double_arrow" />
				</a>
			</div>
		</div>

		<div class="hr" />

		<div class="summary-container">
			<span class="summary-title">
				Funding Direct
			</span>

			<div class="nb-vspacer" />

			<span class="summary-desc">
				Need to formalize a loan you've already agreed with someone? Want to approach someone 
				close to you with a formal proposal? Send a Loan Proposal to your other party offering 
				your terms and once you both agree you can make a loan happen.
			</span>

			<div class="nb-vspacer" />

			<div class="action">
				<a class="flat-button" @click="$emit('goto', 'FundingDirect')">
					Learn more

					<q-icon class="icon" name="arrow_forward" />
				</a>

				<a class="button" @click="$router.push('/login')">
					Borrow from a Friend

					<q-icon class="icon" name="double_arrow" />
				</a>
			</div>
		</div>

		<div class="nb-vspacer xxxlarge" />
	</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
	name: 'BorrowingPage_Overview',

	props: {
		isActive: false
	},

	setup() {
		// TODO
	}
})
</script>

<style lang="scss" scoped>
.borrowing-page-overview {
	display: flex;
	flex-direction: column;
	position: absolute;
	opacity: 0;
	transition: opacity 1s;
	height: 0;
	overflow: hidden;

	.summary-container {
		display: flex;
		flex-direction: column;

		.summary-title {
			display: flex;
			//background: linear-gradient(90deg, #EA7914 0, #EA7914 10px, transparent 10px, transparent 100%);
			//padding-left: 15px;
			font-size: 20px;
			line-height: 24px;
			font-family: MartelRegular;
			font-weight: bold;
			color: #5761A7;
		}

		.summary-desc {
			font-size: 17px;
			line-height: 22px;
			color: rgba(#000, 0.7);
		}

		.action {
			display: flex;
			justify-content: space-between;
			align-items: center;
			z-index: 1;

			.flat-button {
				display: flex;
				align-items: center;
				color: #5761A7;
				text-transform: uppercase;
				font-weight: bolder;
				font-size: 13px;
				cursor: pointer;

				.icon {
					color: #EA7914;
					font-weight: bold;
					font-size: 16px;
					margin-left: 4px;
					cursor: pointer;
				}
			}

			.button {
				display: flex;
				justify-content: center;
				align-items: center;
				width: fit-content;
				//height: 36px;
				color: rgba(#fff, 0.9);
				border-radius: 4px;
				font-weight: bolder;
				font-size: 13px;
				text-transform: uppercase;
				padding: 2px 8px;
				background: linear-gradient(135deg, #FF9800 0, #EA7916 100%);
				//background-color: #FF9800;
				cursor: pointer;

				.icon {
					color: rgba(#fff, 0.5);
					margin-left: 12px;
					font-size: 16px;
					cursor: pointer;
				}
			}
		}
	}

	.hr {
		display: flex;
		width: 30%;
		margin-top: 28px;
		border-top: 1px dotted #FF9800;
		padding-bottom: 36px;
	}
}

.borrowing-page-overview.active {
	opacity: 1;
	height: unset;
	overflow: unset;
}
</style>